.card{
    border: 2px solid #cccccc !important;
    border-radius: 1px !important;
    font-family: 'Roboto', sans-serif;
    padding: 0.5em;
    min-height: 65vh;
    max-width: 1280px;
    width: 90%; 
    display: flex;
    flex-direction: row;

}

@media (max-width: 850px) {
    .card{
        width: 95%; 
        flex-direction: column;
    }

    .leftText{
        margin-right: 0px  !important;
    }

    .rightImage{
        margin-top: 0.5em !important;
    }
}

.leftText{
    flex: 2;
    /* height: 100%; */
    border: 1px solid #000;
    margin-right: 0.5em;
    display: flex;
    align-items:stretch;
    justify-content: center;
    flex-direction: column;
}

div.jumbotron{
    width: 100%;
}

.heading{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    justify-self: flex-start;
    background-color: purple;
    flex: 1;
}

.mintHeading{
    flex: 3;
    padding: 2em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex: 2;
    width: 100%;
    /* background-color: red; */
}

.signHeading{
    flex: 3;
    padding: 2em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex: 2;
    width: 100%;
}

.or{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 1.5em;
    /* background-color: royalblue; */
}


.rightImage{
    flex: 1;
    display : flex;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    border: 1px solid #000;
    
}

.psy-image{
    max-height: 300px;
    width: auto;
}

.button-block{
    width: 100%;
}

.display-3{
    font-size: 2.25em !important;
    font-weight: 500 !important;
}

.lead{
    font-size: 1em !important;
    font-weight: 50 !important;
    font-weight: lighter;
}